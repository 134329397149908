<template>
  <div class="table">
    <div class="table-box">
      <el-table border :data="tableData">
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column v-for="(item, index) in tableKeyValue" :key="index" :prop="item.prop" :label="item.label">
        </el-table-column>
        <!-- <el-table-column prop="date" label="日期">
      </el-table-column>
      <el-table-column prop="name" label="姓名">
      </el-table-column>
      <el-table-column prop="address" label="地址">
      </el-table-column> -->
      </el-table>
    </div>

    <div class="bottom">
      <div class="bts">
        <!-- <span @click="tableClosePopup" class="blue">取消</span>
        <span @click="tableClosePopup">确定</span> -->
        <el-button size="small" @click="tableClosePopup">取消</el-button>
        <el-button type="primary" size="small" @click="tableClosePopup">确定</el-button>
      </div>
      <el-pagination :page-size="100" layout="total, prev, pager, next" :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableKeyValue: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    tableData: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  data () {
    return {
    }
  },
  methods: {
    // 修改 table cell边框的背景色
    tableCellStyle () {
      return 'border: 1px solid #01EEFD; background-color: #051A2C;text-align: center;border-left: none;border-top: none;'
    },
    // 修改 table header cell的背景色
    tableHeaderCellStyle () {
      return 'border-color: transparent; color: #fff; text-align: center;background-color: #02457B;'
    },
    tableClosePopup () {
      this.$emit('tableClosePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.table {
  position: relative;
  width: 100%;
  height: 100%;

  /deep/.el-table__cell {
    text-align: center;
  }

  .table-box {
    width: 100%;
    height: calc(100% - 60px);
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 50px;

    .bts {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 40%;
      height: 40px;
    }
  }
}
</style>
