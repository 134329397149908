<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-01-19 15:49:09
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-02-18 10:18:04
 * @FilePath: \visions\src\views\zhyl\monitoring\prescriptionRun\physioPopup.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="popup1">
    <div class="title">
      <span>选择理疗处方</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <TableEl :tableKeyValue="tableKeyValue" :tableData="tableData" @tableClosePopup="closePopup"></TableEl>
    </div>
  </div>
</template>

<script>
import TableEl from './elTable.vue'
export default {
  components: {
    TableEl
  },
  data () {
    return {
      tableKeyValue: [
        { prop: 'id', label: '编号' },
        { prop: 'name', label: '项目名称' },
        { prop: 'type', label: '仪器名称' },
        { prop: 'gg', label: '方法' },
        { prop: 'jl', label: '部位' },
        { prop: 'yf', label: '频率' },
        { prop: 'yx', label: '影响' }
      ],
      tableData: [
        { id: '2021340001', name: '艾灸', type: '艾灸床', gg: '按钮操作', jl: '全身', yf: '每天20分钟', yx: '腰部有不适感' }
      ]
    }
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 30px;
    font-weight: 500;
  }

  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 63px);
    margin-top: 10px;
    background-color: #fff;
    // padding: 50px 60px;
    // background-image: url('../../img/ssjk/background.png');
    // background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 14px;
      right: 30px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }
  }
}
</style>
